//import React from 'react';
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  useUpdate,
  useNotify,
  useRedirect,
  useRecordContext,
} from "react-admin";
import { BsCurrencyDollar } from "react-icons/bs";
import { BsCurrencyRupee } from "react-icons/bs";
import { FaBeer } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

import { TiTick } from "react-icons/ti";

import { Navigate } from "react-router-dom";

// import Currency from 'react-currency-icons';

import { Stacked, Pie, Button, LineChart, SparkLine } from "../../Components";
import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "../../Data/dummy";
import {
  useStateContext,
  useUserContext,
} from "../../Contexts/ContextProvider";
import product9 from "../../Data/product9.jpg";
import QRCode from "../../img/UPIQRCode.png";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Dashboard = () => {
  const { currentColor, currentMode } = useStateContext();

  const user = useUserContext();

  const redirect = useRedirect();

  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  function copyFunction() {
    /* Get the text field */
    var copyText = "wjyzcthwnn";

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText);

    /* Alert the copied text */
    //alert("Copied the text: " + copyText.value);
    alert("Copied");
  }

  // if (!authenticated) {
  //   // return <Navigate replace to="/login" />;
  //   redirect('/login');
  //   } else {
  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3  bg-no-repeat  bg-center"> */}
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 w-81 p-6 rounded-2xl"> */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-104 rounded-xl w-full lg:w-81 p-8 pt-9 m-3  bg-no-repeat  bg-center">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl text-orange-600">{`Hello CeleBrighter!`}</h1>
              <br />
              <p className="font-bold text-green-600">
                Welcome to CeleBrighter.com.
              </p>
              <br />
              <p className="font-bold text-blue-600">
                Our Mission is to Donate Atleast 10,000 Smart Phones to 10,000
                Poor Students Who Want To Learn Skills Using A Smart Phone.
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3  bg-no-repeat  bg-center"> */}
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 w-81 p-6 rounded-2xl"> */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-xl w-full lg:w-81 p-8 pt-9 m-3  bg-no-repeat  bg-center">
          <div className="flex justify-between items-center">
            <div>
              {/* <h2>{`Hello ${user}!`}</h2> */}

              <p className="text-2xl text-blue-600">
                Please Donate A Smart Phone To Underprivileged Students.{" "}
              </p>
              <br />
              <p className="font-bold text-green-600">
                At Least Contribute A Portion Of The Amount To Be A Part Of Our
                Mission.
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3  bg-no-repeat  bg-center"> */}
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 w-81 p-6 rounded-2xl"> */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-104 rounded-xl w-full lg:w-81 p-8 pt-9 m-3  bg-no-repeat  bg-center">
          <div className="flex justify-between items-center">
            <div>
              {/* <h2>{`Hello ${user}!`}</h2>   */}

              <p className="text-2xl text-blue-600">
                Please Send Your Donation To Following UPI Id or Scan The QR
                Code.
              </p>
              <br />
              <p className="font-bold text-green-600">1973.tv@ybl</p>
              <br />
              <img src={QRCode} alt="" />
              <p className="text-2xl text-blue-600"></p>
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3  bg-no-repeat  bg-center"> */}
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 w-81 p-6 rounded-2xl"> */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-xl w-full lg:w-81 p-8 pt-9 m-3  bg-no-repeat  bg-center">
          <div className="flex justify-between items-center">
            <div>
              {/* <h2>{`Hello ${user}!`}</h2>   */}

              <p className="text-2xl text-blue-600">
                After Sending Your Donation, Post Your Screenshot In Posts{" "}
              </p>
              <br />
              <p className="font-bold text-red-600"></p>
              <center>
                <a href="https://celebrighter.com/posts">
                  <div className="mt-6 bg-center">
                    <Button
                      color="white"
                      bgColor={currentColor}
                      text="Click Here to Post"
                      borderRadius="10px"
                    />
                  </div>
                </a>
              </center>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
  //}
};

export default Dashboard;
